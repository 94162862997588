<template>
    <div>
    <!-- 新增角色 -->
    <el-dialog class="DepartmentBox" title="新增角色" center :visible.sync="addroleVisible" width="400px" :close-on-click-modal="true">
      <div class="rolebody">
        <el-form :model="roleInfo" :inline="false" label-width="110px">
          <el-form-item label="角色名称">
            <el-input v-model="roleInfo.RoleName" autocomplete="off" size="mini" />
          </el-form-item>
          <el-form-item label="角色描述">
            <el-input v-model="roleInfo.RoleDesc" autocomplete="off" size="mini" />
          </el-form-item>
        </el-form>
        添加该角色成员
        <div class="roleuser">
          <el-button class="adduserbuton" size="small" icon="el-icon-plus" circle @click="openconnUser(3)" />
          <el-tooltip v-for="(item, index) in roleInfo.UserList" :key="index" class="item" effect="dark" :content="item.NickName" placement="bottom">
            <div class="addedUser">
              <i class="el-icon-user" />
              <span class="close" @click="removeUser(index)">x</span>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div slot="footer" class="caozuo">
        <el-button @click="addroleVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRoleInfo">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择成员 -->
    <el-dialog class="DepartmentBox" title="添加新成员" center :visible.sync="seletUserVisible" width="25%" :close-on-click-modal="false">
      <div class="rolebody">
        <el-tabs v-model="selectUseractiveName" @tab-click="selectUseractiveNamehandleClick">
          <el-tab-pane label="成员" name="user" />
        </el-tabs>
        <div v-if="selectUseractiveName === 'user'" class="selectuser">
          <el-input
            v-model="selectUserkeywords"
            size="small"
            placeholder="搜索成员"
            prefix-icon="el-icon-search"
          />
          <div class="selectUserTree">
            <el-tree
              ref="allusertree"
              :data="connuserlist"
              node-key="value"
              highlight-current
              :filter-node-method="alluserfilterNode"
            >
              <span slot-scope="{ node, data }" style="width:100%">
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan">
                  {{ node.label }}</span>
                <!-- nodeType- 0：部门 1：员工 -->
                <span v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkeduser(data.ischecked,data.value,data)" /></span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer t-right">
        <el-button @click="seletUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="doconnuser">确 定</el-button>
      </div>
    </el-dialog>
    </div>
</template>
<script>
// import PageHeader from '@/components/PageHeader.vue'
import $request from '@/request/contactsapi.js'
export default {
  name: 'AddRole',
  props: {
    sourceType: {
      type: Number,
      default: 0
    } // 1: 权限增加人员
  },
  data() {
    return {
      // 角色弹框
      addroleVisible: false,
      roleInfo: {
        IDX: 0,
        RoleName: '',
        RoleDesc: '',
        UserList: [
          {
            UserID: 119590,
            username: 'monica'
          },
          {
            UserID: 119580,
            username: 'phoebe'
          }
        ] // 角色下的人员
      },
      // 选择人员
      connuserlist: [], // 成员
      seletUserVisible: false,
      selectUseractiveName: 'user', // 选择人员
      selectUserkeywords: '',
      checkeduserIdList: [], // 选中的人员
      checkeduserList: [], // 选中的人员(复选框)
      selectuserType: 0
    }
  },
  mounted() {
  },
  methods: {
    // 打开新增角色框 初始化
    openaddroleUser(_type = 1) {
      const _this = this
      _this.roleInfo = {
        IDX: 0,
        RoleName: '',
        RoleDesc: '',
        UserList: [
        ] // 角色下的人员
      }
      _this.addroleVisible = true
    },
    // 编辑角色
    editRoleInfo() {
      const _this = this
      _this.fullscreenLoading = true
      $request.SubmitRole(this.roleInfo).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          if (_this.sourceType === 1) {
            _this.$parent.getRoledata()
          }
          _this.addroleVisible = false
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 处理角色选择人
    editrolecheckeduser(ischecked, data) {
      if (ischecked) {
        if (this.checkeduserIdList.indexOf(data) < 0) {
          this.checkeduserIdList.push(data)
        }
      } else {
        this.checkeduserIdList.splice(this.checkeduserIdList.indexOf(data), 1)
      }
    },
    //
    pushuserarr(ischecked, data) {
      const _this = this
      switch (_this.selectuserType) {
        case 1:
          _this.selectcheckeduser(ischecked, data)
          break
        case 2:
          _this.selectcheckeduser(ischecked, data)
          break
        case 3:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 4:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 5:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 6:
          _this.editrolecheckeduser(ischecked, data)
          break
        default: console.log('未找到需要关联人员的组织！！_type：' + _this.selectuserType)
      }
    },
    // pushArrytype  -1 不需要push  1 push到 checkeduserIdList  数组里
    dgcheck(ischecked, data, pushArrytype) {
      const _this = this
      data.ischecked = ischecked
      if (pushArrytype === 1) {
        _this.pushuserarr()
      }
      if (data.children) {
        data.children.forEach(element => {
          return _this.dgcheck(ischecked, element, pushArrytype)
        })
      }
    },
    // 选中人员
    checkeduser(ischecked, val, item) {
      const _this = this
      _this.pushuserarr(ischecked, item)
      if (item.children) {
        _this.dgcheck(ischecked, item, 1)
      }
      console.log(this.checkeduserIdList)
    },
    // 确认选中人员
    doconnuser() {
      const _this = this
      // selectuserType 1： 角色添加人员 2 部门添加人员 3：新增角色时 4：新增部门时部门主管 5：新增部门时分管领导 6: 编辑人员 直属领导
      switch (_this.selectuserType) {
        case 3:
          _this.doeditroleconnuser()
          break
        default: console.log('未找到需要关联人员的组织！！_type：' + _this.selectuserType)
      }
    },
    // 获取选择人员时 ，成员列表 _type 选择人员类型 1： 角色添加人员 2 部门添加人员 3：新增角色时 4：新增部门时 部门主管 5: 新增部门时 分管领导 6：新增人员时，直属领导
    openconnUser(_type) {
      const _this = this
      _this.isshowpassword = true
      _this.selectuserType = _type
      _this.checkeduserIdList = [] // 清空选择的人员项
      _this.getSelectUserQueryListOfDept()
      _this.seletUserVisible = true
    },
    // 编辑角色的时候 确认选中人员
    doeditroleconnuser() {
      const _this = this
      _this.checkeduserIdList.forEach((ele) => {
        _this.roleInfo.UserList.push({ UserID: ele.IDX, NickName: ele.label })
      })
      _this.seletUserVisible = false
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.add_fullscreenLoading = true
      _this.connuserlist = []
      $request.SelectUserQueryListOfDept().then(res => {
        _this.add_fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
        }
      })
    },
    // selectUseractiveNamehandleClick
    selectUseractiveNamehandleClick(val) {
      console.log(val)
    }
  } // methods ends
}
</script>
<style scoped lang='scss'>
@import '@/style/search.scss';
@import '@/style/contactsIndex.scss';
</style>
<style lang='scss'>
.userstatus {
  .el-icon-remove {
    color: #9696a5;
  }
}
.el-table .userrowColor {
  color: #c6c6d4;
}
.adduserdia .el-dialog  .el-dialog__body {
  height: 400px;
  overflow: auto;
}
.roleremakr .el-input__inner{
  height:100px;
}
.roleuser .el-icon-plus:before {
    color: #f35e07;
}
</style>
