<template>
  <div>
    <!-- 添加新部门 -->
    <el-dialog class="DepartmentBox" title="添加新部门" center :visible.sync="adddeptVisible" width="30%" :close-on-click-modal="false">
      <el-form class="NewDepartment" :model="deptinfo" :inline="false" label-width="100px">
        <el-form-item label="部门名称">
          <el-input v-model="deptinfo.GroupName" size="mini" autocomplete="off" />
        </el-form-item>
        <el-form-item label="部门描述" class="miaoshu">
          <el-input v-model="deptinfo.GroupDesc" type="textarea" size="mini" autocomplete="off" class="roleremakr" />
        </el-form-item>
        <el-form-item label="本地部门编号">
          <el-input v-model="deptinfo.SimsSectionID" size="mini" autocomplete="off" />
        </el-form-item>
        <div class="roleuser adddeptbox">
          部门主管<br>
          <el-button class="adduserbuton" size="medium" icon="el-icon-plus" circle @click="openconnUser(4)" />
          <el-tooltip v-if="deptinfo.DepartmentLeadersID > 0" class="item" effect="dark" :content="deptinfo.DepartmentLeadersName" placement="bottom">
            <div class="addedUser">
              <i class="el-icon-user" />
              <span class="close" @click="deptinfo.DepartmentLeadersID = 0">x</span>
            </div>
          </el-tooltip>
        </div>
        <div class="roleuser adddeptbox">
          分管领导<br>
          <el-button class="adduserbuton" size="medium" icon="el-icon-plus" circle @click="openconnUser(5)" />
          <el-tooltip v-if="deptinfo.ChargeLeaderID > 0" class="item" effect="dark" :content="deptinfo.ChargeLeaderName" placement="bottom">
            <div class="addedUser">
              <i class="el-icon-user" />
              <span class="close" @click="deptinfo.ChargeLeaderID = 0">x</span>
            </div>
          </el-tooltip>
        </div>
        <el-form-item label="是否被@" label-width="80px" class="adddeptbox">
          <el-radio v-model="deptinfo.AllowAt" :label="1">是</el-radio>
          <el-radio v-model="deptinfo.AllowAt" :label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="默认通知" label-width="80px" class="adddeptbox">
          <el-radio v-model="deptinfo.IsDefault" :label="1">是</el-radio>
          <el-radio v-model="deptinfo.IsDefault" :label="0">否</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="caozuo t-right">
        <el-button @click="adddeptVisible = false">取 消</el-button>
        <el-button type="primary" @click="editdeptInfo">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择成员 -->
    <el-dialog class="DepartmentBox" title="添加新成员" center :visible.sync="seletUserVisible" width="25%" :close-on-click-modal="false">
      <div class="rolebody">
        <el-tabs v-model="selectUseractiveName" @tab-click="selectUseractiveNamehandleClick">
          <el-tab-pane label="成员" name="user" />
        </el-tabs>
        <div v-if="selectUseractiveName === 'user'" class="selectuser">
          <el-input
            v-model="selectUserkeywords"
            size="small"
            placeholder="搜索成员"
            prefix-icon="el-icon-search"
          />
          <div class="selectUserTree">
            <el-tree
              ref="allusertree"
              :data="connuserlist"
              node-key="value"
              highlight-current
              :filter-node-method="alluserfilterNode"
            >
              <span slot-scope="{ node, data }" style="width:100%">
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan">
                  {{ node.label }}</span>
                <!-- nodeType- 0：部门 1：员工 -->
                <span v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkeduser(data.ischecked,data.value,data)" /></span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer t-right">
        <el-button @click="seletUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="doconnuser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import PageHeader from '@/components/PageHeader.vue'
import $request from '@/request/contactsapi.js'
export default {
  name: 'AddDept',
  props: {
    sourceType: {
      type: Number,
      default: 0
    } // 1: 权限增加人员
  },
  data() {
    return {
      // 新增部门
      adddeptVisible: false,
      deptacttiveid: 0,
      deptacttiveInfo: null,
      deptinfo: {
        GroupName: '',
        GroupDesc: '',
        SimsSectionID: '', // 部门编号
        DepartmentLeadersID: 119580, // 部门主管
        DepartmentLeadersName: 'phoebe', // 名称
        ChargeLeaderID: 119590, // 分管领导
        ChargeLeaderName: 'monica', // 分管领导
        AllowAt: '0',
        IsDefault: '0',
        ParentID: 0 // 上级部门
      },
      // 选择人员
      connuserlist: [], // 成员
      seletUserVisible: false,
      selectUseractiveName: 'user', // 选择人员
      selectUserkeywords: '',
      checkeduserIdList: [], // 选中的人员
      checkeduserList: [], // 选中的人员(复选框)
      selectuserType: 0
    }
  },
  mounted() {
  },
  methods: {
    // 编辑部门
    editdeptInfo() {
      const _this = this
      _this.fullscreenLoading = true
      $request.SubmitDept(this.deptinfo).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          if (_this.sourceType === 1) {
            _this.$parent.getDeptList()
          }
          _this.adddeptVisible = false
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 打开新增部门框 初始化
    openadddeptUser() {
      const _this = this
      _this.deptinfo = {
        GroupName: '',
        GroupDesc: '',
        SimsSectionID: '', // 部门编号
        DepartmentLeadersID: 0, // 部门主管
        DepartmentLeadersName: '', // 名称
        ChargeLeaderID: 0, // 分管领导
        ChargeLeaderName: '', // 分管领导
        AllowAt: 0,
        IsDefault: 0,
        ParentID: 0 // 上级部门
      }
      _this.adddeptVisible = true
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 处理角色选择人
    editrolecheckeduser(ischecked, data) {
      if (ischecked) {
        if (this.checkeduserIdList.indexOf(data) < 0) {
          this.checkeduserIdList.push(data)
        }
      } else {
        this.checkeduserIdList.splice(this.checkeduserIdList.indexOf(data), 1)
      }
    },
    //
    pushuserarr(ischecked, data) {
      const _this = this
      switch (_this.selectuserType) {
        case 1:
          _this.selectcheckeduser(ischecked, data)
          break
        case 2:
          _this.selectcheckeduser(ischecked, data)
          break
        case 3:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 4:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 5:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 6:
          _this.editrolecheckeduser(ischecked, data)
          break
        default: console.log('未找到需要关联人员的组织！！_type：' + _this.selectuserType)
      }
    },
    // pushArrytype  -1 不需要push  1 push到 checkeduserIdList  数组里
    dgcheck(ischecked, data, pushArrytype) {
      const _this = this
      data.ischecked = ischecked
      if (pushArrytype === 1) {
        _this.pushuserarr()
      }
      if (data.children) {
        data.children.forEach(element => {
          return _this.dgcheck(ischecked, element, pushArrytype)
        })
      }
    },
    // 选中人员
    checkeduser(ischecked, val, item) {
      const _this = this
      _this.pushuserarr(ischecked, item)
      if (item.children) {
        _this.dgcheck(ischecked, item, 1)
      }
      console.log(this.checkeduserIdList)
    },
    // 编辑部门的时候 确认选中部门主管
    doeditdeptconnuser2() {
      const _this = this
      if (_this.checkeduserIdList.length > 1) {
        _this.$message('分管领导只能选择一个人！，当前选中人数：' + _this.checkeduserIdList.length)
        return false
      }
      _this.deptinfo.ChargeLeaderID = _this.checkeduserIdList[0].IDX
      _this.deptinfo.ChargeLeaderName = _this.checkeduserIdList[0].label
      _this.seletUserVisible = false
    },
    // 编辑部门的时候 确认选中部门主管
    doeditdeptconnuser() {
      const _this = this
      if (_this.checkeduserIdList.length > 1) {
        _this.$message('部门主管只能选择一个人！，当前选中人数：' + _this.checkeduserIdList.length)
        return false
      }
      _this.deptinfo.DepartmentLeadersID = _this.checkeduserIdList[0].IDX
      _this.deptinfo.DepartmentLeadersName = _this.checkeduserIdList[0].label
      _this.seletUserVisible = false
    },
    // 确认选中人员
    doconnuser() {
      const _this = this
      // selectuserType 1： 角色添加人员 2 部门添加人员 3：新增角色时 4：新增部门时部门主管 5：新增部门时分管领导 6: 编辑人员 直属领导
      switch (_this.selectuserType) {
        case 4:
          _this.doeditdeptconnuser()
          break
        case 5:
          _this.doeditdeptconnuser2()
          break
        default: console.log('未找到需要关联人员的组织！！_type：' + _this.selectuserType)
      }
    },
    // 获取选择人员时 ，成员列表 _type 选择人员类型 1： 角色添加人员 2 部门添加人员 3：新增角色时 4：新增部门时 部门主管 5: 新增部门时 分管领导 6：新增人员时，直属领导
    openconnUser(_type) {
      const _this = this
      _this.isshowpassword = true
      _this.selectuserType = _type
      _this.checkeduserIdList = [] // 清空选择的人员项
      _this.getSelectUserQueryListOfDept()
      _this.seletUserVisible = true
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.add_fullscreenLoading = true
      _this.connuserlist = []
      $request.SelectUserQueryListOfDept().then(res => {
        _this.add_fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
        }
      })
    },
    // selectUseractiveNamehandleClick
    selectUseractiveNamehandleClick(val) {
      console.log(val)
    }
  } // methods ends
}
</script>
<style scoped lang='scss'>
@import '@/style/search.scss';
@import '@/style/contactsIndex.scss';
</style>
<style lang='scss'>
.userstatus {
  .el-icon-remove {
    color: #9696a5;
  }
}
.el-table .userrowColor {
  color: #c6c6d4;
}
.adduserdia .el-dialog  .el-dialog__body {
  height: 400px;
  overflow: auto;
}
.roleremakr .el-input__inner{
  height:100px;
}
.roleuser .el-icon-plus:before {
    color: #f35e07;
}

</style>
