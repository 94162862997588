import $userRequest from '@/request/contactsapi.js'
import $jurisdictionReques from '@/request/jurisdictionApi.js'
import addUser from '@/components/addUser'
import addDept from '@/components/addDept'
import addRole from '@/components/addRole'
const jurisdictionIndex = {
  name: 'jurisdictionIndex',
  components: {
    addUser,
    addDept,
    addRole
  },
  data() {
    return {
      fullscreenLoading: false,
      selectUseractiveName: 'user',
      // 人员搜索
      selectUserkeywords: '',
      // 人员列表
      connuserlist: [],
      connuserlistback: [],
      // 选中的人员
      checkedUserInfo: null,
      // 部门列表
      selectUserDeptData: [],
      selectUserDeptDataback: [],
      // 选中的部门
      checkedDeptInfo: null,
      // 角色列表
      selectUserroleData: [],
      selectUserroleDataback: [],
      // 选中的角色
      checkedRoleInfo: null,
      // ==============================================right begin==================================================
      rightLoading: false,
      allMenuCheck: false,
      isIndeterminate: false,
      dataList: [
        {
          IDX: 1,
          TypeID: 0,
          DisplayName: '客商首营',
          isshow: true,
          IsChecked: false,
          SubList: [
            {
              IDX: 74,
              TypeID: 0,
              DisplayName: '客商首营',
              checked: false,
              datatype: 1,
              IsChecked: false,
              SubList: [
                {
                  IDX: 100,
                  TypeID: 1,
                  DisplayName: '查看',
                  IsChecked: true
                },
                {
                  IDX: 101,
                  TypeID: 1,
                  DisplayName: '创建',
                  IsChecked: true
                },
                {
                  IDX: 102,
                  TypeID: 1,
                  DisplayName: '修改',
                  IsChecked: true
                },
                {
                  IDX: 103,
                  TypeID: 1,
                  DisplayName: '下载/导出',
                  IsChecked: false
                }
              ]
            },
            {
              IDX: 74,
              TypeID: 0,
              DisplayName: '客商列表',
              IsChecked: false,
              datatype: 1,
              SubList: [
                {
                  IDX: 100,
                  TypeID: 1,
                  DisplayName: '查看',
                  IsChecked: true
                },
                {
                  IDX: 102,
                  TypeID: 1,
                  DisplayName: '修改',
                  IsChecked: true
                },
                {
                  IDX: 103,
                  TypeID: 1,
                  DisplayName: '下载/导出',
                  IsChecked: false
                }
              ]
            }
          ]
        },
        {
          IDX: 1,
          TypeID: 0,
          DisplayName: '仓储管理',
          isshow: true,
          IsChecked: false,
          SubList: [
            {
              IDX: 74,
              TypeID: 0,
              DisplayName: '商品库存',
              IsChecked: false,
              datatype: 1,
              SubList: [
                {
                  IDX: 100,
                  TypeID: 1,
                  DisplayName: '查看',
                  IsChecked: true
                },
                {
                  IDX: 101,
                  TypeID: 1,
                  DisplayName: '创建',
                  IsChecked: false
                },
                {
                  IDX: 102,
                  TypeID: 1,
                  DisplayName: '修改',
                  IsChecked: false
                },
                {
                  IDX: 103,
                  TypeID: 1,
                  DisplayName: '下载/导出',
                  IsChecked: false
                }
              ]
            },
            {
              IDX: 74,
              TypeID: 0,
              DisplayName: '商品入库',
              IsChecked: false,
              datatype: 1,
              SubList: [
                {
                  IDX: 100,
                  TypeID: 1,
                  DisplayName: '查看',
                  IsChecked: true
                },
                {
                  IDX: 102,
                  TypeID: 1,
                  DisplayName: '修改',
                  IsChecked: false
                },
                {
                  IDX: 103,
                  TypeID: 1,
                  DisplayName: '下载/导出',
                  IsChecked: false
                }
              ]
            }
          ]
        }
      ],
      curentPower: null, // 是否有权限数据
      // ==============================================right end==================================================
      // 设置权限类型 user: 人员 dept： 部门 role：角色
      settingType: 'user',
      // 选中的数组数据
      checkedArrData: [],
      isShowLeft: true, // 是否显示左侧
      paraIDX: 0, // 部门/角色/人员idx
      showText: ''
    }
  },
  mounted() {
    this.paraIDX = this.$route.params.idx
    const info = this.$route.params.info
    const paraType = this.$route.params.type
    if (this.paraIDX > 0) {
      this.selectUseractiveName = paraType
      this.settingType = paraType
      switch (paraType) {
        case 'user':
          this.showText = '当前正在为人员：' + info + ' 设置权限'
          this.checkedUserInfo = {
            IDX: this.paraIDX,
            GroupName: info
          }
          break
        case 'dept':
          this.showText = '当前正在为部门：' + info + ' 设置权限'
          this.checkedDeptInfo = {
            IDX: this.paraIDX,
            GroupName: info
          }
          break
        case 'role':
          this.showText = '当前正在为角色：' + info + ' 设置权限'
          this.checkedRoleInfo = {
            IDX: this.paraIDX,
            GroupName: info
          }
          break
        default:
      }
      this.getRight(this.paraIDX)
      this.isShowLeft = false
    } else {
      this.getSelectUserQueryListOfDept()
      this.getDeptList()
      this.getRoledata()
      // 获取右边的数据
      this.getRight()
    }
  },
  watch: {
    selectUserkeywords(val) {
      this.$refs.allusertree.filter(val)
    }
  },
  methods: {
    // 打开新增部门框
    openadddeptUser() {
      this.$refs.addDept.openadddeptUser()
    },
    // 打开新增角色框
    openaddroleUser() {
      this.$refs.addRole.openaddroleUser()
    },
    // 打开新增成员框
    openaddUser() {
      this.$refs.addUser.openaddUser()
    },
    // ==============================================right begin==================================================
    // 处理选中/取消数据  vaule 选中/不选中 _data : idx
    dealCheckedData(vaule, _data) {
      if (vaule) {
        // 判断 checkedArrData 是否有 没有则添加
        if (this.checkedArrData.indexOf(_data) < 0) {
          this.checkedArrData.push(_data)
        }
      } else {
        // 从 checkedArrData 去掉数据
        this.checkedArrData.splice(this.checkedArrData.indexOf(_data), 1)
      }
      // console.log(this.checkedArrData)
    },
    allMenuCheckChange(val) {
      this.dataList.forEach(ele => {
        this.dealMainMeauCheck(val, null, ele, 5)
      })
      if (val) {
        this.isIndeterminate = false
        this.allMenuCheck = true
      } else {
        this.isIndeterminate = false
        this.allMenuCheck = false
      }
    },
    // 父级选中/不选中 type 1: 不处理 2：主菜单勾选 3：子菜单勾选 4：功能模块勾选, 5 所有权限处理 6:按钮勾选
    dealMainMeauCheck(vaule, _data, _item, type, parentItem, upparentItem, mainmeau) {
      const _this = this
      _this.fullscreenLoading = true
      // 递归处理
      if (!vaule) {
        if (type === 4 && _item.ButtonType === 1) {
          let checkcount = 0
          parentItem.SubList.forEach(ele => {
            if (ele.IsChecked) {
              checkcount++
            }
          })
          if (checkcount === 0) {
            _this.dataCheck(vaule, _item)
          }
        } else {
          _this.dataCheck(vaule, _item)
        }
      } else {
        _this.dataCheck(vaule, _item)
      }
      _item.IsChecked = vaule
      switch (type) {
        case 2:
          _this.dealallmeau()
          break
        case 3:
          _this.dealmainmeau2(vaule, _item, parentItem, upparentItem, 3, mainmeau)
          break
        case 4:
          _this.dealmainmeau2(vaule, _item, parentItem, upparentItem, 4, mainmeau)
          break
        case 6:
          _this.dealmainmeau2(vaule, _item, parentItem, upparentItem, 6, mainmeau)
          break
        default: console.log('未找到匹配处理项：' + type)
      }
      _this.fullscreenLoading = false
    },
    // 处理选中
    dealChecked(check, item, mainMeau, childItem, modelItem) {
      mainMeau.IsChecked = check
      if (childItem) {
        mainMeau.SubList.forEach(child => {
          // child 子菜单
          if (childItem.IDX === child.IDX) {
            child.IsChecked = check
            if (modelItem) {
              child.SubList.forEach(model => {
                // model 模块
                if (model.IDX === modelItem.IDX) {
                  model.IsChecked = check
                }
                // 如果不是查看按钮则勾选查看模块
                if (item.ButtonType !== 1) {
                  if (model.ButtonType === 1) {
                    this.dataCheck(check, model)
                  }
                }
              })
            }
          }
        })
      }
    },
    // 处理不选中
    dealUnChecked(mainMeau) {
      let childcount = 0 // 子菜单勾选条数
      let modelcount = 0 // 模块勾选条数
      let buttoncount = 0 // 按钮勾选条数
      // 循环子菜单
      childcount = 0
      mainMeau.SubList.forEach(child => {
        // child
        if (child.SubList) {
          // 循环模块
          modelcount = 0
          child.SubList.forEach(model => {
            // model
            if (model.SubList) {
              // 循环模块
              buttoncount = 0
              model.SubList.forEach(button => {
                if (button.IsChecked) buttoncount++
              })
              // 如果按钮没有勾选则模块不勾选
              if (buttoncount === 0 && model.SubList.length > 0) {
                model.IsChecked = false
              }
            }
            if (model.IsChecked) modelcount++
          })
          if (modelcount === 0 && child.SubList.length > 0) child.IsChecked = false
        }
        if (child.IsChecked) childcount++
      })
      if (childcount === 0 && mainMeau.SubList.length > 0) {
        mainMeau.IsChecked = false
      }
    },
    // 递归选中/不选中
    dataCheck(check, item) {
      item.IsChecked = check
      this.dealCheckedData(check, item.IDX)
      if (item.SubList && item.SubList.length > 0) {
        item.SubList.forEach(ele => {
          if (ele.SubList) {
            this.dataCheck(check, ele)
          }
        })
      }
    },
    // 处理数据勾选以及选中状态
    dealDataAndCheck(_this) {
      _this.checkedArrData = []
      _this.dataList.forEach(ele => {
        _this.initCheckData(ele, _this)
      })
      _this.initCheckStatus(_this.dataList, _this)
      console.log(_this.checkedArrData)
    },
    // 当子菜单勾选时
    dealmainmeau2(check, item, parentItem, upparentItem, type, mainmeau) {
      if (check) {
        switch (type) {
          case 6:
            // 按钮
            this.dealChecked(check, item, mainmeau, upparentItem, parentItem)
            break
          case 4:
            // 模块
            this.dealChecked(check, item, mainmeau, parentItem, item)
            break
          case 3:
            // 子菜单
            this.dealChecked(check, item, mainmeau, item, null)
            break
          default:
        }
        this.dealDataAndCheck(this)
      } else {
        // 取消模块
        if (type === 4) {
          if (item.ButtonType === 1) {
            let othercount = 0
            parentItem.SubList.forEach(ele => {
              if (ele.ButtonType !== 1 && ele.IsChecked) {
                othercount++
              }
            })
            if (othercount > 0) {
              this.$message('当前菜单 包含除查看外的其他功能权限，查看不能被移除！')
              this.$nextTick(() => {
                item.IsChecked = !item.IsChecked
                this.dealDataAndCheck(this)
              })
              return false
            }
          }
        }
        // 取消按钮
        if (type === 6) {
          if (item.ButtonType === 1) {
            let othercount = 0
            upparentItem.SubList.forEach(ele => {
              if (ele.ButtonType !== 1 && ele.IsChecked) {
                othercount++
              }
            })
            parentItem.SubList.forEach(ele => {
              if (ele.ButtonType !== 1 && ele.IsChecked) {
                othercount++
              }
            })
            if (othercount > 0) {
              this.$message('当前菜单 包含除查看外的其他功能权限，查看不能被移除！')
              this.$nextTick(() => {
                item.IsChecked = !item.IsChecked
                this.dealDataAndCheck(this)
              })
              return false
            }
          }
        }
        this.dealUnChecked(mainmeau)
        this.dealDataAndCheck(this)
      }
    },
    // 当子菜单勾选时，处理主菜单勾选状态
    dealmainmeau(check, item, parentItem, upparentItem, type) {
      let checkcount = 0
      parentItem.SubList.forEach(ele => {
        if (ele.IsChecked) {
          checkcount++
        }
      })
      if (check) {
        // 本条选中
        if (checkcount === parentItem.SubList.length) {
          parentItem.ishalf = false
          parentItem.IsChecked = true
        } else {
          parentItem.ishalf = true
          parentItem.IsChecked = true
        }
      } else {
        // 本条取消
        if (checkcount === 0) {
          parentItem.ishalf = false
          parentItem.IsChecked = false
        } else {
          parentItem.ishalf = true
          parentItem.IsChecked = true
        }
      }
      // 如果是模块 找出对应主菜单
      // item 当前模块对象 parentItem 子菜单对象 upparentItem 主菜单对象
      if (type === 4) {
        this.dealmainmeau(check, item, upparentItem, null, 3)
        // 处理所有权限
        this.dealallmeau()
      } else {
        // 处理所有权限
        this.dealallmeau()
      }
    },
    // 当主菜单勾选时，处理所有权限的勾选
    dealallmeau() {
      // 遍历主菜单
      let checkcount = 0
      this.dataList.forEach(ele => {
        if (ele.IsChecked) {
          checkcount++
        }
      })
      if (checkcount === 0) {
        this.isIndeterminate = false
        this.allMenuCheck = false
      } else if (checkcount === this.dataList.length) {
        this.isIndeterminate = false
        this.allMenuCheck = true
      } else {
        this.isIndeterminate = true
        this.allMenuCheck = true
      }
    },
    // 验证设置权限
    confirmSetPower() {
      const _this = this
      if (!_this.checkedArrData || _this.checkedArrData.length < 1) {
        _this.$message.error('请设置权限再保存！')
        return false
      }
      let message = ''
      if (_this.settingType === 'user') {
        if (!_this.checkedUserInfo) {
          _this.$message.error('没有为当前权限选择人员！')
          return false
        }
        message = '确认为人员 ' + _this.checkedUserInfo.GroupName + ' 设置权限吗？'
      } else if (_this.settingType === 'dept') {
        if (!_this.checkedDeptInfo) {
          _this.$message.error('没有为当前权限选择部门！')
          return false
        }
        message = '确认为部门 ' + _this.checkedDeptInfo.GroupName + ' 设置权限吗？'
      } else if (_this.settingType === 'role') {
        if (!_this.checkedRoleInfo) {
          _this.$message.error('没有为当前权限选择角色！')
          return false
        }
        message = '确认为人员 ' + _this.checkedRoleInfo.RoleName + ' 设置权限吗？'
      } else {
        console.log('未找到匹配的权限设置：' + _this.settingType)
        return false
      }
      _this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        switch (_this.settingType) {
          case 'user':
            _this.setUserPower()
            break
          case 'dept':
            _this.setDeptPower()
            break
          case 'role':
            _this.setRolePower()
            break
          default: return false
        }
      }).catch(() => {
      })
    },
    // 设置部门权限
    setDeptPower() {
      const _this = this
      _this.fullscreenLoading = true
      // 处理参数
      const paras = {
        IDX: _this.checkedDeptInfo.IDX,
        Permissions: []
      }
      _this.checkedArrData.forEach(ele => {
        paras.Permissions.push({
          IDX: ele
        })
      })
      $jurisdictionReques.SetDeptPermission(paras).then(res => {
        _this.fullscreenLoading = false
        _this.rightLoading = false
        if (res.RetCode === '0') {
          // _this.dataList = res.Message
          _this.getRight(_this.checkedDeptInfo.IDX)
          _this.$message.success('设置权限成功')
        } else {
          _this.$message.error('设置权限出错：' + res.RetMsg)
        }
      })
    },
    // 设置角色权限
    setRolePower() {
      const _this = this
      _this.fullscreenLoading = true
      // 处理参数
      const paras = {
        IDX: _this.checkedRoleInfo.IDX,
        Permissions: []
      }
      _this.checkedArrData.forEach(ele => {
        paras.Permissions.push({
          IDX: ele
        })
      })
      $jurisdictionReques.SetRolePermission(paras).then(res => {
        _this.fullscreenLoading = false
        _this.rightLoading = false
        if (res.RetCode === '0') {
          // _this.dataList = res.Message
          _this.getRight(_this.checkedRoleInfo.IDX)
          _this.$message.success('设置权限成功')
        } else {
          _this.$message.error('设置权限出错：' + res.RetMsg)
        }
      })
    },
    // 设置人员权限
    setUserPower() {
      const _this = this
      _this.fullscreenLoading = true
      // 处理参数
      const paras = {
        IDX: _this.checkedUserInfo.IDX,
        Permissions: []
      }
      _this.checkedArrData.forEach(ele => {
        paras.Permissions.push({
          IDX: ele
        })
      })
      $jurisdictionReques.SetUserPermission(paras).then(res => {
        _this.fullscreenLoading = false
        _this.rightLoading = false
        if (res.RetCode === '0') {
          // _this.dataList = res.Message
          _this.getRight(_this.checkedUserInfo.IDX)
          _this.$message.success('设置权限成功')
        } else {
          _this.$message.error('设置权限出错：' + res.RetMsg)
        }
      })
    },
    // 获取右边的权限数据
    getRight(_idx) {
      const _this = this
      _this.rightLoading = true
      const paras = { DeptID: 0, RoleID: 0, UserID: 0 }
      if (_this.settingType === 'user') {
        paras.UserID = _idx
      } else if (_this.settingType === 'dept') {
        paras.DeptID = _idx
      } else if (_this.settingType === 'role') {
        paras.RoleID = _idx
      }
      $jurisdictionReques.GetPermissionList(paras).then(res => {
        _this.rightLoading = false
        if (res.RetCode === '0') {
          _this.dataList = res.Message.DataList
          _this.curentPower = res.Message.Permission
          _this.checkedArrData = []
          _this.dataList.forEach(ele => {
            _this.initCheckData(ele, _this)
          })
          _this.initCheckStatus(_this.dataList, _this)
        }
      })
    },
    // 初始化 选中数据
    initCheckData(item, _this) {
      if (item.IsChecked) {
        _this.checkedArrData.push(item.IDX)
      }
      if (item.SubList) {
        item.SubList.forEach(ele => {
          _this.initCheckData(ele, _this)
        })
      }
    },
    // 初始化选中状态
    initCheckStatus(item, _this) {
      // 1：子页面
      // 2：主菜单
      // 3: 所有权限
      let mainhalf = 0 // 主菜单半选中条数
      let maincheckcount = 0 // 主菜单选中条数
      let childcheckcount = 0 // 子菜单选中条数
      let childishalf = 0 // 子菜单半选中条数
      let modelcheckcount = 0 // 每个子菜单选中的模块
      let modelishalf = 0 // 每个子菜单半选中条数
      let bottoncount = 0 // 每个模块选中的条数
      item.forEach((main) => {
        // 主菜单
        childcheckcount = 0
        childishalf = 0
        modelcheckcount = 0
        bottoncount = 0
        if (main.IsChecked) maincheckcount++
        if (main.SubList) {
          // 子菜单
          main.SubList.forEach(child => {
            // childcheckcount = 0
            if (child.IsChecked) childcheckcount++
            // 模块
            if (child.SubList) {
              modelcheckcount = 0
              modelishalf = 0
              child.SubList.forEach(model => {
                if (model.IsChecked) {
                  modelcheckcount++
                }
                bottoncount = 0
                if (model.SubList) {
                  model.SubList.forEach(button => {
                    if (button.IsChecked) {
                      bottoncount++
                    }
                  })
                  if (bottoncount === 0) {
                    model.ishalf = false
                  } else if (bottoncount > 0 && model.SubList.length > bottoncount) {
                    model.ishalf = true
                    modelishalf++
                  } else {
                    model.ishalf = false
                  }
                }
              })
              //   if (modelcheckcount === 0) {
              //     child.ishalf = false
              //   } else if (modelcheckcount > 0 && child.SubList.length > modelcheckcount) {
              //     child.ishalf = true
              //     childishalf++
              //   } else {
              //     child.ishalf = false
              //   }
              if (modelishalf === 0 && modelcheckcount > 0) {
                if (modelcheckcount === child.SubList.length) {
                  child.ishalf = false
                } else {
                  child.ishalf = true
                }
              } else if (modelishalf > 0 && child.SubList.length > modelishalf) {
                child.ishalf = true
              } else {
                child.ishalf = false
              }
              // 如果主菜单 有半选中则
              if (child.ishalf) {
                childishalf++
              }
            }
          })
          if (childishalf === 0 && childcheckcount > 0) {
            if (childcheckcount === main.SubList.length) {
              main.ishalf = false
            } else {
              main.ishalf = true
            }
          } else if (childishalf > 0 && main.SubList.length > childishalf) {
            main.ishalf = true
          } else {
            main.ishalf = false
          }
        }
        // 如果主菜单 有半选中则
        if (main.ishalf) {
          mainhalf++
        }
      })
      // 处理所有权限
      if (mainhalf === 0 && maincheckcount > 0) {
        _this.allMenuCheck = true
        if (maincheckcount === item.length) {
          _this.isIndeterminate = false
        } else {
          _this.isIndeterminate = true
        }
      } else if (mainhalf > 0 && item.length > mainhalf) {
        _this.allMenuCheck = true
        _this.isIndeterminate = true
      } else {
        _this.allMenuCheck = false
        _this.isIndeterminate = false
      }
    },
    // 处理所有数据是否部门选择
    // ==============================================right end==================================================
    // 选中角色
    checkedRole(ischecked, item) {
      const _this = this
      _this.checkedRoleInfo = item
      _this.getRight(item.IDX)
    //   if (ischecked) {
    //     if (_this.checkedRoleInfo) {
    //       // 取消当前已经选中
    //       _this.dealRoleNoCheck(_this.checkedRoleInfo.IDX, false)
    //     }
    //     _this.checkedRoleInfo = item
    //     _this.getRight(item.IDX)
    //   } else {
    //     _this.checkedRoleInfo = null
    //     _this.getRight(null)
    //   }
    },
    // 选中部门
    checkedDept(ischecked, item) {
      const _this = this
      _this.checkedDeptInfo = item
      _this.getRight(item.IDX)
    //   if (ischecked) {
    //     if (_this.checkedDeptInfo) {
    //       _this.dealDeptNoCheck(_this.checkedDeptInfo.IDX, false)
    //     }
    //     _this.checkedDeptInfo = item
    //     _this.getRight(item.IDX)
    //   } else {
    //     _this.checkedDeptInfo = null
    //     _this.getRight(null)
    //   }
    },
    // 选中人员
    checkeduser(ischecked, item) {
      const _this = this
      if (item.nodeType !== 1) {
        _this.checkedUserInfo = null
        _this.getRight(null)
      } else {
        _this.checkedUserInfo = item
        _this.getRight(item.IDX)
      }
    //   if (ischecked) {
    //     if (_this.checkedUserInfo) {
    //       _this.dealUserNoCheck(false)
    //     }
    //     _this.checkedUserInfo = item
    //     // 获取人员的权限信息
    //     _this.getRight(item.IDX)
    //   } else {
    //     _this.checkedUserInfo = null
    //     _this.getRight(null)
    //   }
    },
    // 角色列表
    getRoledata(_type = 1) {
      const _this = this
      $userRequest.GetRoleList({ pageIndex: 1, pageSize: -1 }).then(res => {
        if (res.RetCode === '0') {
          _this.selectUserroleData = res.Message
          _this.selectUserroleDataback = res.Message
        }
      })
    },
    // 部门列表
    getDeptList() {
      const _this = this
      $userRequest.GetDeptList().then(res => {
        if (res.RetCode === '0') {
          _this.selectUserDeptData = res.Message
          _this.selectUserDeptDataback = res.Message
        }
      })
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.fullscreenLoading = true
      _this.connuserlist = []
      $userRequest.SelectUserQueryListOfDept().then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
          _this.connuserlistback = res.Message
        }
      })
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 切换成员/部门/角色
    selectUseractiveNamehandleClick(val) {
      this.fullscreenLoading = true
      // this.selectUseractiveName = val
      if (this.selectUseractiveName !== this.settingType) {
        if (this.selectUseractiveName === 'user') {
          // 清空选中的部门/角色
        //   if (this.checkedDeptInfo) {
        //     this.dealDeptNoCheck(this.checkedDeptInfo.IDX, false)
        //   }
        //   if (this.checkedRoleInfo) {
        //     this.dealRoleNoCheck(this.checkedRoleInfo.IDX, false)
        //   }
          this.checkedDeptInfo = null
          this.checkedRoleInfo = null
        } else if (this.selectUseractiveName === 'dept') {
          // 清空选中的人员/角色
        //   if (this.checkedRoleInfo) {
        //     this.dealRoleNoCheck(this.checkedRoleInfo.IDX, false)
        //   }
        //   if (this.checkedUserInfo) {
        //     this.dealUserNoCheck(false)
        //   }
          this.checkedUserInfo = null
          this.checkedRoleInfo = null
        } else if (this.selectUseractiveName === 'role') {
          // 清空选中的部门/角色
        //   if (this.checkedDeptInfo) {
        //     this.dealDeptNoCheck(this.checkedDeptInfo.IDX, false)
        //   }
        //   if (this.checkedUserInfo) {
        //     this.dealUserNoCheck(false)
        //   }
          this.checkedUserInfo = null
          this.checkedDeptInfo = null
        }
        this.settingType = this.selectUseractiveName
        this.getRight(null)
        this.fullscreenLoading = false
      }
    },
    // 处理部门不选中
    dealDeptNoCheck(_idx, check) {
      this.selectUserDeptData.forEach((ele) => {
        if (_idx === ele.IDX) {
          ele.ischecked = check
          return false
        }
      })
    },
    // 处理角色不选中
    dealRoleNoCheck(_idx, check) {
      this.selectUserroleData.forEach((ele) => {
        if (_idx === ele.IDX) {
          ele.ischecked = check
          return false
        }
      })
    },
    // 处理成员不选中
    dealUserNoCheck(check) {
      this.connuserlist.forEach((ele) => {
        this.dgdealUserNoCheck(ele, check)
      })
    },
    dgdealUserNoCheck(data, check) {
      if (data.nodeType === 1) {
        data.ischecked = check
      }
      if (data.children) {
        data.children.forEach(ele => {
          this.dgdealUserNoCheck(ele, check)
        })
      }
    }
  }
}
export default jurisdictionIndex
