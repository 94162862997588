<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="flex jurisdictionIndex">
    <div v-if="isShowLeft" class="left p-lr-20">
      <el-tabs v-model="selectUseractiveName" :stretch="true" @tab-click="selectUseractiveNamehandleClick">
        <el-tab-pane label="成员" name="user" />
        <el-tab-pane label="部门" name="dept" />
        <el-tab-pane label="角色" name="role" />
      </el-tabs>
      <div v-if="selectUseractiveName === 'user'" class="selectuser">
        <el-input
          v-model="selectUserkeywords"
          size="small"
          placeholder="搜索成员"
          prefix-icon="el-icon-search"
        />
        <div class="selectUserTree">
          <div class="addrole" @click="openaddUser"><i class="el-icon-circle-plus" /> 添加新成员</div>
          <el-tree
            ref="allusertree"
            :data="connuserlist"
            node-key="value"
            highlight-current
            :filter-node-method="alluserfilterNode"
          >
            <span slot-scope="{ node, data }" style="width:100%" @click="checkeduser(data.ischecked,data)">
              <span>
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan">
                  {{ node.label }}</span>
              </span>
              <!-- nodeType- 0：部门 1：员工 -->
              <!-- <span  v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox @change="checkeduser(data.ischecked,data)"  v-model="data.ischecked"></el-checkbox></span> -->
            </span>
          </el-tree>
        </div>
      </div>
      <div v-if="selectUseractiveName === 'dept'" class="selectuser">
            <div class="addrole" @click="openadddeptUser"><i class="el-icon-circle-plus" /> 添加新部门</div>
        <el-tree
          ref="tree"
          :data="selectUserDeptData"
          node-key="value"
          highlight-current
        >
          <span slot-scope="{ node, data }" style="width:100%" @click="checkedDept(data.ischecked,data)">
            <span :class="checkedDeptInfo && checkedDeptInfo.IDX === data.IDX ? 'deptnamespancolor': ''">
              <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
              <span class="deptnamespan">
                {{ node.label }}</span>
            </span>
            <!-- <span class="deptcountspan"><el-checkbox  @change="checkedDept(data.ischecked,data)" v-model="data.ischecked"></el-checkbox></span> -->
          </span>
        </el-tree>
      </div>
      <div v-if="selectUseractiveName === 'role'" class="selectuser">
              <div class="addrole" @click="openaddroleUser"><i class="el-icon-circle-plus" /> 添加新角色</div>
        <div v-for="(item, index) in selectUserroleData" :key="index" :class="checkedRoleInfo && checkedRoleInfo.IDX === item.IDX ? 'selectuserrole selectuserrolecolor' :'selectuserrole'">
          <div @click="checkedRole(item.ischecked,item)">
            <span class="rolenamespan">
              {{ item.RoleName }}</span>
          </div>
          <!-- <span class="deptcountspan"><el-checkbox  @change="checkedRole(item.ischecked,item)" v-model="item.ischecked"></el-checkbox></span> -->
        </div>
      </div>
    </div>
    <div class="right p-l-10 p-tb-10 p-right-20 m-left-20">
      <div class="checkallmeau p-tb-10">
        <el-checkbox v-model="allMenuCheck" :indeterminate="isIndeterminate" @change="allMenuCheckChange(allMenuCheck)">所有权限</el-checkbox>
        <span class="powerInfo m-left-20">{{ showText }}</span>
        <el-button
          style="float:right"
          type="primary"
          size="mini"
          @click="confirmSetPower"
        >
          保存设置</el-button>
      </div>
      <div v-loading="rightLoading" class="meaus m-top-10">
        <el-card v-for="(item, index) in dataList" :key="index" class="box-card m-bottom-20">
          <div slot="header" class="clearfix">
            <el-checkbox v-model="item.IsChecked" class="eachitem" :indeterminate="item.ishalf" @change="dealMainMeauCheck(item.IsChecked, item.IDX, item, 2, null, null, item)"> <span class="cardTitle">{{ item.DisplayName }}</span></el-checkbox>
            <el-link style="float: right; font-size:14px" :underline="false" @click="item.isshow = !item.isshow">
              <i v-if="item.isshow" class="el-icon-arrow-up" />
              <i v-if="!item.isshow" class="el-icon-arrow-down" /></el-link>
          </div>
          <div v-if="item.isshow" class="content">
            <div v-for="(each, eachindex) in item.SubList" :key="eachindex" class="item p-tb-5">
              <el-checkbox v-model="each.IsChecked" :indeterminate="each.ishalf" class="eachitem" @change="dealMainMeauCheck(each.IsChecked, each.IDX, each, 3, item, null, item)">{{ each.DisplayName }}</el-checkbox>
              <el-checkbox v-for="(eachitem, eachitemindex) in each.SubList" :key="eachitemindex" v-model="eachitem.IsChecked" :indeterminate="eachitem.ishalf" class="eachitem" @change="dealMainMeauCheck(eachitem.IsChecked, eachitem.IDX, eachitem, 4, each, item, item)">
                {{ eachitem.DisplayName }}
                <el-popover
                  v-if="eachitem.SubList && eachitem.SubList.length > 0"
                  placement="right"
                  width="auto"
                  trigger="hover"
                >
                  <el-checkbox v-for="(buttonitem, buttonitemindex) in eachitem.SubList" :key="buttonitemindex" v-model="buttonitem.IsChecked" class="eachitem" @change="dealMainMeauCheck(buttonitem.IsChecked, buttonitem.IDX, buttonitem, 6, eachitem, each, item)">{{ buttonitem.DisplayName }}</el-checkbox>
                  <!-- <el-checkbox>111</el-checkbox> -->
                  <i slot="reference" class="el-icon-more" />
                </el-popover>
              </el-checkbox>
              <!-- <div class="itemright">
                        <el-select  size="mini" v-model="each.datatype" placeholder="请选择">
                          <el-option label="仅针对自己的数据" :value=1></el-option>
                          <el-option label="仅针对本部门的数据" :value=2></el-option>
                          <el-option label="仅针对本部门以及下级部门的数据" :value=3></el-option>
                          <el-option label="全部数据" :value=4></el-option>
                        </el-select>
                    </div> -->
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <!-- 新增人员 -->
    <addUser ref="addUser"/>
    <!-- 新增部门 -->
    <addDept ref="addDept"  :sourceType = "1" />
    <!-- 新增角色 -->
    <addRole ref="addRole"  :sourceType = "1" />
  </div>
</template>
<script>
import jurisdictionIndex from '@/minxin/jurisdiction/jurisdictionIndex.js'
export default jurisdictionIndex
</script>
<style>
.el-card__header {
    background-color: #f1f9ff;
}
</style>
<style scoped  lang='scss'>
@import '@/style/index.scss';
@import '@/style/main.scss';
@import '@/style/jurisdiction.scss';
@import '@/style/contactsIndex.scss';
</style>
